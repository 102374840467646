.portfolio-desc {
  margin-bottom: 80px;
  font-size: 18px;
}

.portfolio-container {
  width: 100%;
  max-width: 1280px;
  padding: 0 60px 30px 60px;
  min-height: 100vh;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.project {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 30px 30px;
  height: 280px;
  margin-bottom: 20px;
  color: black;
}

.project-image-container {
  width: 100%;
  height: 280px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.project-image-container::after {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
}

.project-image-container {
  position: relative;
}
/* 
.background {
  background-image: url('https://upload.wikimedia.org/wikipedia/en/6/62/Kermit_the_Frog.jpg');
  width: 200px;
  height: 200px;
} */

.project-overlay {
  width: 100%;
  height: 280px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 3;
}

.project-overlay:hover {
  opacity: 1;
}

.project-image {
  width: 100%;
  transform: translate(-50%, -50%);
  position: relative;
  left: 50%;
  top: 50%;
  z-index: 1;
}

.project-text {
  position: absolute;
  bottom: 0;
  left: 28px;
  right: 28px;
  bottom: 28px;
  z-index: 5;
}

.project-title {
  color: white;
  font-size: 30px;
  margin-bottom: 5px;
}

.project-desc {
  font-size: 16px;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 640px) {
  .portfolio-container {
    padding: 40px 10px 60px 10px;
  }

  .portfolio-desc {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
  }

  .project {
    height: 160px;
  }

  .project-image-container {
    height: 160px;
  }

  .project-overlay {
    height: 160px;
  }
}

.mobile-nav {
  background-color: white;
  box-shadow: 0 1px 0 #eceeef;
}

.icon-button {
  position: absolute;
  right: 14px;
  background-color: Transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: black;
}

.drawer {
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  padding: 0 20px 10px 20px;
  background-color: white;
  box-shadow: 0 1px 0 #eceeef;
}

.mobile-nav-menu {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: 12px;
  list-style: none;
  padding: 0;
  margin-top: 4px;
}

.mobile-nav-links {
  text-decoration: none;
  font-size: 18px;
  color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: color 0.2s;
}

.mobile-nav-links:hover {
  color: #daa520;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 125px;
}

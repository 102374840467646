.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1.2s linear infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.panel-1 {
  display: block;
  overflow: hidden;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.header {
  font-size: 66px;
  margin: 120px 0 0 0;
  font-weight: 700;
  margin-top: 60px;
}

.intro {
  font-size: 20px;
  margin: 20px 10px 0 0;
  width: auto;
  line-height: 28px;
}

.button {
  background-color: black;
  border: none;
  border-radius: 30px;
  padding: 18px 22px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin: 40px 10px 0 0;
  transition: background-color 0.3s ease !important;
}

.button:hover {
  background-color: #daa520;
  border-color: #daa520;
}

.resume-button {
  padding: 16px 20px;
  background-color: white;
  border: 2px solid black;
  color: black;
  transition: border-color 0.3s ease !important;
}

.resume-button:hover {
  border-color: #daa520;
  background-color: white;
}

.arrow {
  text-align: center;
  margin-top: 120px;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.image {
  width: 580px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.panel-2 {
  display: block;
  padding: 60px 60px 0 60px;
}

.sub-header {
  font-size: 40px;
  margin-top: 60px;
}

.grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: 20px;
  grid-row-gap: 10px;
  margin-bottom: 100px;
}

.item-container {
  position: relative;
  width: 100%;
}

.item-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.panel-3 {
  display: block;
  padding: 0 60px;
}

.portfolio {
  display: flex;
  justify-content: space-between;
  height: 160px;
  background-color: #fcf8f5;
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px 80px;
}

.portfolio-text {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
  margin-right: 20px;
}

.portfolio-image {
  height: 100%;
  margin-top: 10px;
  margin-right: -10px;
}

.portfolio-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-top: 28px;
  transition: text-decoration 3s ease;
  width: 220px;
  height: 30px;
}

.arrow-1 {
  margin-left: 5px;
  margin-top: 2px;
}

.panel-4 {
  display: block;
  padding: 0 60px;
}

.text {
  font-size: 20px;
  font-weight: 600;
}

.resume {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 160px;
  background-color: #f5f9fa;
  border-radius: 10px;
  margin-top: 20px;
  padding: 40px 80px;
}

.resume-text {
  font-size: 24px;
  color: grey;
  margin-bottom: 0;
  margin-right: 20px;
}

.resume-image {
  height: 100%;
}

.resume-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  margin-top: 28px;
  transition: text-decoration 3s ease;
  width: 220px;
  height: 30px;
}

.arrow-2 {
  margin-left: 5px;
  margin-top: 2px;
}

.panel-5 {
  display: block;
  padding: 0 60px;
}

.contact-container {
  width: fit-content;
  margin: 20px 0 60px 0;
}

.contact {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  transition: opacity 0.3s ease;
  margin-right: 16px;
  background-color: #ffe3e3;
  border: none;
}

.contact:hover {
  opacity: 0.5;
}

@keyframes move-right {
  0% {
    margin-left: 5px;
  }
  10% {
    margin-left: 6px;
  }
  25% {
    margin-left: 8px;
  }
  40% {
    margin-left: 9px;
  }
  50% {
    margin-left: 10px;
  }
  60% {
    margin-left: 9px;
  }
  75% {
    margin-left: 8px;
  }
  90% {
    margin-left: 6px;
  }
  100% {
    margin-left: 5px;
  }
}

.portfolio-link:hover > .arrow-1 {
  animation: move-right 1s linear infinite;
}

.resume-link:hover > .arrow-2 {
  animation: move-right 1s linear infinite;
}

@media only screen and (max-width: 1220px) {
  .image {
    width: 420px;
  }

  .header {
    font-size: 56px;
    margin-top: 20px;
  }

  .sub-header {
    font-size: 32px;
  }

  .intro {
    font-size: 18px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 960px) {
  .image {
    width: 360px;
  }

  .header {
    font-size: 42px;
  }

  .button {
    border-radius: 24px;
    padding: 16px 20px;
    margin: 20px 10px 0 0;
  }

  .resume-button {
    border-radius: 24px;
    padding: 14px 18px;
    margin: 20px 10px 0 0;
  }
}

@media only screen and (max-width: 952px) {
  .portfolio-text {
    margin-right: 10px;
    font-size: 22px;
  }

  .resume-text {
    margin-right: 10px;
    font-size: 22px;
  }
}

@media only screen and (max-width: 890px) {
  .portfolio {
    height: 140px;
  }

  .portfolio-image {
    height: 80%;
    margin-top: 5%;
  }

  .portfolio-text {
    margin-right: 10px;
    font-size: 20px;
  }

  .resume {
    height: 140px;
  }

  .resume-image {
    height: 80%;
    margin-top: 5%;
  }

  .resume-text {
    margin-right: 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 807px) {
  .portfolio {
    height: 160px;
  }

  .resume {
    height: 160px;
  }

  .image {
    width: 300px;
  }

  .arrow {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 730px) {
  .portfolio {
    padding: 20px 40px;
  }

  .portfolio-image {
    height: 60%;
    margin-top: 5%;
  }

  .portfolio-text {
    margin-right: 10px;
    font-size: 20px;
  }

  .resume {
    padding: 20px 40px;
  }

  .resume-image {
    height: 60%;
    margin-top: 5%;
  }

  .resume-text {
    margin-right: 10px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .image {
    width: 200px;
  }

  .button {
    margin-top: 20px;
  }

  .resume-button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 640px) {
  .panel-1 {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 10px;
  }

  .panel-2 {
    padding: 60px 10px 10px 10px;
    text-align: center;
  }

  .panel-5 {
    text-align: center;
    padding: 10px;
    margin: -60px 0 60px 0;
  }

  .image {
    width: 240px;
    margin-top: 30px;
  }

  .portfolio {
    flex-direction: column;
    height: 300px;
    justify-content: center;
    text-align: center;
  }

  .portfolio-text {
    width: 200px;
    position: relative;
  }

  .portfolio-image {
    height: 50%;
  }

  .contact-container {
    margin: auto;
  }
}

.nav-items {
  height: 40px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 20px;
  list-style: none;
  text-align: center;
  margin: auto 0;
}

.nav-logo {
  text-decoration: none;
  color: black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: 700;
  font-size: 20px;
  transition: color 0.2s;
}

.nav-logo:hover {
  color: #daa520;
}

.nav-links {
  text-decoration: none;
  font-size: 16px;
  color: #646f79;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nav-links:hover {
  color: #daa520;
}

.right-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 30px;
  width: 125px;
  margin-top: 3px;
}

.social-links {
  color: black;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.social-links:hover {
  color: #daa520;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  font-family: "Inter", sans-serif;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.footer {
  width: 100%;
  height: 50px;
  position: relative;
  text-align: center;
}
